exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-bejamas-js": () => import("./../../../src/pages/projects/bejamas.js" /* webpackChunkName: "component---src-pages-projects-bejamas-js" */),
  "component---src-pages-projects-curiosity-rover-js": () => import("./../../../src/pages/projects/curiosityRover.js" /* webpackChunkName: "component---src-pages-projects-curiosity-rover-js" */),
  "component---src-pages-projects-factly-js": () => import("./../../../src/pages/projects/factly.js" /* webpackChunkName: "component---src-pages-projects-factly-js" */),
  "component---src-pages-projects-idiota-js": () => import("./../../../src/pages/projects/idiota.js" /* webpackChunkName: "component---src-pages-projects-idiota-js" */),
  "component---src-pages-projects-rest-countries-js": () => import("./../../../src/pages/projects/restCountries.js" /* webpackChunkName: "component---src-pages-projects-rest-countries-js" */)
}

